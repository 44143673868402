import React, { useEffect } from "react";

export const BranchPopupOpener = ({ handlePopupOpen }) => {
  useEffect(() => {
    const openers = document.querySelectorAll(".js-ver-locales");

    if (openers && openers.length) {
      openers.forEach((opener) => {
        if (!opener.parentNode) return;
        opener.parentNode.addEventListener("click", (e) => {
          e.preventDefault();

          //Call handle
          handlePopupOpen && handlePopupOpen();
        });
      });
    }
  }, []);

  return <></>;
};
