import React from "react";

export const BranchesPin = ({ item, handleBranchOpen }) => {
  const handleClick = () => {
    handleBranchOpen && handleBranchOpen(item);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: "relative",
        top: "-50px",
        left: "-25px",
        width: "50px",
        height: "50px",
        backgroundImage: "url(assets/images/marker.png)",
      }}
      data-open={item.isOpen}
    ></div>
  );
};
