const BranchesHeaderResumeFilters = ({
  activeState,
  activeTown,
  handleStatePopupState,
  handleTypePopupState,
  handleShowList,
}) => {
  const filterClick = () => {
    handleTypePopupState && handleTypePopupState(true);
  };
  const stateClick = () => {
    handleStatePopupState && handleStatePopupState(true);
  };
  const listClick = () => {
    handleShowList && handleShowList(true);
  };
  return (
    <div className="c-filters__mobile u-hidden-lg">
      <div className="c-filters__group c-filters__group--top">
        <span className="c-location__text c-location__text--location">Ubicación:</span>
        <button className="c-location__current u-tap-size" onClick={stateClick}>
          {activeState}
          {activeTown ? `, ${activeTown}` : ""}
          <span className="c-location__edit icon-edit"></span>
        </button>
      </div>
      <div className="c-filters__group c-filters__group--bottom c-filters__row">
        <button className="c-filters__button u-tap-size" onClick={filterClick}>
          <span className="c-filters__button-icon icon-filter-filled"></span>
          <span className="c-filters__button-text">Filtrar</span>
        </button>
        <button className="c-filters__button u-tap-size" onClick={listClick}>
          <span className="c-filters__button-icon icon-list"></span>
          <span className="c-filters__button-text">Ver Listado</span>
        </button>
      </div>
    </div>
  );
};
export default BranchesHeaderResumeFilters;
