export const parseStore = (json, extraJson) => {
  const { picture } = json?._embedded ?? {};
  const hours = calculateHours(json?.extraData?.profile_client ?? "");

  return {
    id: json.id,
    name: json.name,
    address: json.address,
    businessName: json.businessName,
    email: json.email,
    extraData: json.extraData,
    hours: json.hours,
    phone: json.phone,
    state: json.state,
    town: json.town,
    latitude: extraJson?.latitude,
    longitude: extraJson?.longitude,
    isExpress: extraJson?.isExpress,
    isCoffee: extraJson?.isCoffee,
    isOpen: json.businessName !== "closed" && hours?.isOpen,
    nextHour: hours?.nextHour,
    isClosed: json.businessName === "closed",
    hasWaiter: extraJson?.hasWaiter,
    content: extraJson?.content,
    picture: picture ?? {},
  };
};

const calculateHours = (storeHours) => {
  //LMXJVSF-START-END;LMXJVSF-START-END
  const result = {
    isOpen: false,
    nextHour: "",
  };
  const hours = storeHours?.split(";");
  const now = new Date();
  const nowDayLetter = getDayLetter(now.getDay());
  const nowDateRange = getHourRangeDates(hours, nowDayLetter);

  if (nowDateRange.start && nowDateRange.end) {
    //Open
    if (nowDateRange.start < now && nowDateRange.end > now) {
      result.isOpen = true;
      result.nextHour = nowDateRange.endHour;
    }
    //Is closed, but opens today
    else if (nowDateRange.start > now) {
      result.nextHour = nowDateRange.startHour;
    }
    //Opens tomorrow
    else {
      const tomorrowDayLetter = getDayLetter(now.getDay() + 1);
      const tomorrowDateRange = getHourRangeDates(hours, tomorrowDayLetter);
      result.nextHour = tomorrowDateRange.startHour;
    }
  }
  return result;
};

const getHourRangeDates = (hours, dayLetter) => {
  const result = { start: null, end: null, startHour: null, endHour: null };

  if (hours instanceof Array) {
    hours.every((hour) => {
      const hourParts = hour.split("-");
      if (hourParts[0].indexOf(dayLetter) > -1) {
        result.start = parseHourDate(hourParts[1]);
        result.end = parseHourDate(hourParts[2]);
        result.startHour = hourParts[1];
        result.endHour = hourParts[2];
      }
      return !result.start;
    });
  }
  return result;
};

const parseHourDate = (hourStr) => {
  const hour = new Date();

  if (hourStr && hourStr.indexOf(":") > -1) {
    const hourParts = hourStr.split(":");

    hour.setHours(hourParts[0]);
    hour.setMinutes(hourParts[1]);
    hour.setSeconds(0);
    hour.setMilliseconds(0);
  }

  return hour;
};

const getDayLetter = (dayNumber) => {
  return "DLMXJVS".charAt(dayNumber);
};
