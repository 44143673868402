// Return map bounds based on list of places
export const fitMapBounds = (map, maps, pins, pan) => {
  const bounds = new maps.LatLngBounds();

  pins.forEach((pin) => {
    bounds.extend(new maps.LatLng(pin.latitude, pin.longitude));
  });

  // Fit map to bounds
  map.fitBounds(bounds);
  map.setZoom(map.getZoom() - 1);
  if (pan) {
    map.panBy(-200, 0);
  }
};
