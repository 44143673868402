const BranchesHeaderTop = ({ storesCount, handlePopupClose }) => {
  return (
    <div className="c-branches__header-top">
      <p className="c-branches__title">
        <span className="c-branches__title-icon icon-branches"></span>
        <span className="c-branches__title-text">Nuestros Locales</span>
        <span className="c-branches__qty">
          {storesCount} {storesCount === 1 ? "local encontrado" : "locales encontrados"}
        </span>
      </p>
      <button className="c-branches__close u-tap-size" onClick={() => handlePopupClose && handlePopupClose()}>
        <span className="c-branches__close-icon icon-close"></span>
      </button>
    </div>
  );
};
export default BranchesHeaderTop;
