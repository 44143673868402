import { useState } from "react";
import storesService from "../services/stores";

const BranchesHeaderMobileFilters = ({
  states,
  activeState,
  towns,
  activeTown,
  popupState,
  handleFilter,
  handlePopupState,
}) => {
  const [state, setState] = useState(activeState);
  const [town, setTown] = useState(activeTown);
  const [townsFiltered, setTownsFiltered] = useState(towns);

  const handleStateChange = (e) => {
    const stateSelected = e.target.value;
    //Get state obj
    const stateObj = storesService.getStateObj(states, stateSelected);
    const suggestedTown = storesService.getStartTown(stateObj);

    //Update local states
    setState(stateSelected);
    setTownsFiltered(stateObj?.towns ?? []);
    setTown(suggestedTown);
  };

  const handleFilterClick = () => {
    //Call filter
    handleFilter &&
      handleFilter({
        state,
        town,
      });

    //Close popup
    handlePopupState && handlePopupState(false);
  };

  const handlePopupCloseClick = () => {
    handlePopupState && handlePopupState(false);
  };
  return (
    <div
      id="locationSubmenu"
      className={`c-filters__group c-filters__group--filters c-filters__popup ${popupState && "is-open"}`}
    >
      <div className="c-filters__popup-holder">
        <button className="c-filters__popup-close u-tap-size" onClick={handlePopupCloseClick}>
          <span className="c-filters__popup-close-icon icon-close"></span>
        </button>
        <form className="c-filters__popup-form">
          <span className="c-filters__text">Buscar por ubicación</span>
          <label className="c-filters__select">
            <span className="c-filters__label c-filters__label--subtitle">Departamento:</span>
            <span className="c-filters__select-holder">
              <select
                value={state}
                onChange={handleStateChange}
                className="c-filters__input c-filters__input--select"
                name="city"
              >
                <option value="" disabled>
                  Seleccione un departamento
                </option>
                {states?.map((state, index) => {
                  return (
                    <option value={state.state} key={index}>
                      {state.state}
                    </option>
                  );
                })}
              </select>
              <span className="c-filters__select-text">
                <span className="c-filters__select-span">{!!state ? state : "Seleccione un departamento"}</span>
                <span className="c-filters__select-arrow icon-select-arrow"></span>
              </span>
            </span>
          </label>
          <label className="c-filters__select">
            <span className="c-filters__label c-filters__label--subtitle">Barrio:</span>
            <span className="c-filters__select-holder">
              <select
                value={town}
                onChange={(e) => setTown(e.target.value)}
                className="c-filters__input c-filters__input--select"
                name="town"
              >
                <option value="">Seleccione un barrio</option>
                {townsFiltered?.map((town, index) => {
                  return (
                    <option value={town} key={index}>
                      {town}
                    </option>
                  );
                })}
              </select>
              <span className={`c-filters__select-text ${town ? "" : "c-filters__select-text--placeholder"}`}>
                <span className="c-filters__select-span">{!!town ? town : "Seleccione un barrio"}</span>
                <span className="c-filters__select-arrow icon-select-arrow"></span>
              </span>
            </span>
          </label>
          <div className="c-filters__popup-bottom">
            <button type="button" className="c-filters__popup-submit c-btn u-tap-size" onClick={handleFilterClick}>
              Filtrar
              <span className="c-btn__icon icon-carousel-arrow"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default BranchesHeaderMobileFilters;
