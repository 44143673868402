import { API_DEFAULT_IMAGES_URL, API_IMAGES_URL } from "../configs/api";

const BranchesSheet = ({ store, handleClose }) => {
  const imageURL = store?.picture?.filename
    ? `${API_IMAGES_URL}${store.picture?.id}/125/${store.picture?.filename}`
    : API_DEFAULT_IMAGES_URL;

  return (
    <div className={`c-branches__info c-info ${store ? "is-visible" : ""}`}>
      <div className="c-info__close-holder">
        <button className="c-info__close u-tap-size" onClick={() => handleClose && handleClose()}>
          <span className="c-info__close-icon icon-close"></span>
        </button>
      </div>

      <div className="c-info__image">
        <picture className="c-pic c-pic--abs">
          <img className="c-pic__img" src={imageURL} alt={store?.name} />
        </picture>
      </div>
      <div className="c-info__holder">
        <div className="c-info__top">
          {store?.isClosed ? (
            <span className="c-info__open">Cerrado temporalmente</span>
          ) : store?.isOpen ? (
            <>
              <span className="c-info__open is-open">Abierto</span>
              <span className="c-branches__schedule" style={{ display: "inline-block" }}>
                <span className="c-branches__schedule-icon icon-schedule"></span>
                <span className="c-branches__schedule-text">Cierra a las {store?.nextHour} hs.</span>
              </span>
            </>
          ) : (
            <>
              <span className="c-info__open">Cerrado</span>
              <span className="c-branches__schedule" style={{ display: "inline-block" }}>
                <span className="c-branches__schedule-icon icon-schedule"></span>
                <span className="c-branches__schedule-text">Abre a las {store?.nextHour} hs.</span>
              </span>
            </>
          )}
        </div>
        {store?.isCoffee && <span className="c-branches__type type-coffee">Caffè</span>}
        {store?.isExpress && <span className="c-branches__type type-express">Express</span>}

        <p className="c-info__name">
          <strong>{store?.name}</strong>
        </p>
        <p className="c-info__location">{store?.town}</p>

        <div className="c-info__content" dangerouslySetInnerHTML={{ __html: store?.content }}></div>
        {store?.hasWaiter && (
          <span className="c-info__waiter has-waiter">
            <span className="c-info__waiter-icon icon-waiter"></span>
            <span className="c-info__waiter-text">Cuenta con servicio de mozo.</span>
          </span>
        )}
        <div className="c-info__data">
          <ul className="c-info__data-list">
            <li className="c-info__data-item">
              <span className="c-info__data-item-icon icon-location"></span>
              <p className="c-info__data-item-text">
                <strong>{store?.address}</strong>
              </p>
            </li>
            <li className="c-info__data-item">
              <span className="c-info__data-item-icon icon-schedule-strong"></span>
              <p className="c-info__data-item-text">{store?.hours}</p>
            </li>
            {store?.phone && (
              <li className="c-info__data-item">
                <span className="c-info__data-item-icon icon-tel"></span>
                <p className="c-info__data-item-text">
                  <a href={`tel:+598${store?.phone}`}>{store?.phone}</a>
                </p>
              </li>
            )}
            {store?.email && (
              <li className="c-info__data-item">
                <span className="c-info__data-item-icon icon-mail"></span>
                <p className="c-info__data-item-text">
                  <a href={`mailto:${store?.email}`}>{store?.email}</a>
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default BranchesSheet;
