import storesJson from "../data/stores";
import { parseStore } from "../parsers/store";
import { STORES_EXTRA_DATA_URL } from "../data/api";

const fetchStoresExtraData = async () => {
  const storesJSON = await fetch(STORES_EXTRA_DATA_URL);
  const extraData = await storesJSON.json();
  return extraData;
};

const getAllStores = (extraData) => {
  return storesJson.map((json) => {
    return parseStore(json, extraData[json.id]);
  });
};

const getAllStatesFromStores = (stores) => {
  const states = {};
  stores?.forEach((store) => {
    //Check to create initial json
    if (!(store.state in states)) {
      states[store.state] = {
        state: store.state,
        towns: [],
      };
    }

    //Push towns
    if (states[store.state].towns.indexOf(store.town) === -1) {
      states[store.state].towns.push(store.town);
    }

    //Sort
    states[store.state].towns = states[store.state].towns.sort();
  });
  return Object.values(states);
};

const filterStores = (allStores, filters) => {
  return (allStores ?? []).filter((store) => {
    //Check state
    if (filters.state && store.state !== filters.state) {
      return false;
    }
    if (filters.town && store.town !== filters.town) {
      return false;
    }
    if (filters.isOpen && !store.isOpen) {
      return false;
    }
    if (!((filters.isExpress && store.isExpress) || (filters.isCoffee && store.isCoffee))) {
      return false;
    }
    return true;
  });
};

const getStartState = (states) => {
  const [state] = states ?? [];
  return state ?? "";
};

const getStartTown = (state) => {
  const [town] = state?.towns ?? [];
  const length = state?.towns ? state?.towns?.length : 0;
  return length === 1 ? town : "";
};

const getStateObj = (states, state) => {
  let stateObj = null;
  if (states instanceof Array) {
    states.every((stateJson) => {
      if (stateJson.state === state) {
        stateObj = stateJson;
      }
      return !stateObj;
    });
  }
  return stateObj ?? {};
};

const toExport = {
  getAllStores,
  getAllStatesFromStores,
  getStartState,
  getStartTown,
  filterStores,
  getStateObj,
  fetchStoresExtraData,
};
export default toExport;
