import { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { DEFAULT_MAPS_OPTIONS, GOOGLE_MAPS_API_KEY, MAP_STYLES } from "../data/maps";
import { fitMapBounds } from "../services/maps";
import { BranchesPin } from "./BranchesPin";
import { isMobile } from "../services/window";

const BranchesMap = ({ stores, storeCenter, handleBranchOpen }) => {
  const mapsRef = useRef({});

  const handleOnGoogleApiLoaded = ({ map, maps }) => {
    //Load map ref
    mapsRef.current = { map, maps };

    //get bounds and fit it
    fitMapBounds(map, maps, stores, !isMobile());
  };

  useEffect(() => {
    if (mapsRef.current.map && mapsRef.current.maps) {
      //Check items length
      if (!stores.length) {
        return;
      }

      //get bounds and fit it
      fitMapBounds(mapsRef.current.map, mapsRef.current.maps, stores, !isMobile());
    }
  }, [stores]);

  useEffect(() => {
    if (mapsRef.current.map && mapsRef.current.maps) {
      //Center
      fitMapBounds(mapsRef.current.map, mapsRef.current.maps, storeCenter ? [storeCenter] : stores, !isMobile());
    }
  }, [storeCenter]);

  return (
    <div className="c-pic c-pic--abs" style={{ height: "calc(100vh - 156px)", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={DEFAULT_MAPS_OPTIONS.center}
        defaultZoom={DEFAULT_MAPS_OPTIONS.zoom}
        options={{
          maxZoom: DEFAULT_MAPS_OPTIONS.maxZoom,
          styles: MAP_STYLES,
          zoomControlOptions: {
            style: 1,
            position: 1,
          },
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleOnGoogleApiLoaded}
      >
        {stores &&
          stores.map((item) => {
            return (
              <BranchesPin
                key={item.id}
                item={item}
                lat={+item.latitude}
                lng={+item.longitude}
                handleBranchOpen={handleBranchOpen}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};
export default BranchesMap;
