import { useState } from "react";
import { STORES_FILTERS } from "../data/storesFilters";
import { isMobile } from "../services/window";

const BranchesHeaderTypeFilters = ({
  activeIsOpen,
  activeIsExpress,
  activeIsCoffee,
  popupState,
  handleFilter,
  handlePopupState,
}) => {
  const [isOpen, setIsOpen] = useState(activeIsOpen);
  const [isExpress, setIsExpress] = useState(activeIsExpress);
  const [isCoffee, setIsCoffee] = useState(activeIsCoffee);
  const isMobileWindow = isMobile();

  const handleOpenChanged = (event) => {
    handleFilter &&
      handleFilter({
        isOpen: event.target.checked,
      });
  };
  const handleExpressChanged = (event) => {
    handleFilter &&
      handleFilter({
        isExpress: event.target.checked,
      });
  };
  const handleCoffeeChanged = (event) => {
    handleFilter &&
      handleFilter({
        isCoffee: event.target.checked,
      });
  };

  const handleFilterClick = () => {
    //Call filter
    handleFilter &&
      handleFilter({
        isOpen,
        isExpress,
        isCoffee,
      });

    //Close popup
    handlePopupState && handlePopupState(false);
  };

  const handlePopupCloseClick = () => {
    handlePopupState && handlePopupState(false);
  };
  return (
    <div
      id="filtersSubmenu"
      className={`c-filters__group c-filters__group--filters c-filters__popup ${popupState && "is-open"}`}
    >
      <div className="c-filters__popup-holder">
        <button className="c-filters__popup-close u-tap-size" onClick={handlePopupCloseClick}>
          <span className="c-filters__popup-close-icon icon-close"></span>
        </button>
        <form className="c-filters__popup-form">
          <span className="c-filters__text">Filtros</span>
          <div className="c-filters__group-col">
            <label className="c-filters__checkbox">
              {isMobileWindow ? (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="open"
                  value={STORES_FILTERS.OPEN}
                  checked={isOpen}
                  onChange={(event) => setIsOpen(event.target.checked)}
                />
              ) : (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="open"
                  value={STORES_FILTERS.OPEN}
                  checked={activeIsOpen}
                  onChange={handleOpenChanged}
                />
              )}
              <span className="c-filters__checkbox-btn"></span>
              <span className="c-filters__label c-filters__checkbox-label c-filters__checkbox-label--open">
                <span className="c-filters__checkbox-label-text">Abierto</span>
              </span>
            </label>
          </div>
          <div className="c-filters__group-col">
            <span className="c-filters__label c-filters__label--subtitle">Tipo:</span>
            <label className="c-filters__checkbox">
              {isMobileWindow ? (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="express"
                  value={STORES_FILTERS.EXPRESS}
                  checked={isExpress}
                  onChange={(event) => setIsExpress(event.target.checked)}
                />
              ) : (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="express"
                  value={STORES_FILTERS.EXPRESS}
                  checked={activeIsExpress}
                  onChange={handleExpressChanged}
                />
              )}
              <span className="c-filters__checkbox-btn"></span>
              <span className="c-filters__label c-filters__checkbox-label c-filters__checkbox-label--type type-express">
                <span className="c-filters__checkbox-label-text">Express</span>
              </span>
            </label>
            <label className="c-filters__checkbox">
              {isMobileWindow ? (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="coffee"
                  value={STORES_FILTERS.COFFEE}
                  checked={isCoffee}
                  onChange={(event) => setIsCoffee(event.target.checked)}
                />
              ) : (
                <input
                  className="c-filters__checkbox-input"
                  type="checkbox"
                  name="coffee"
                  value={STORES_FILTERS.COFFEE}
                  checked={activeIsCoffee}
                  onChange={handleCoffeeChanged}
                />
              )}
              <span className="c-filters__checkbox-btn"></span>
              <span className="c-filters__label c-filters__checkbox-label c-filters__checkbox-label--type type-coffee">
                <span className="c-filters__checkbox-label-text">Caffè</span>
              </span>
            </label>
          </div>
          <div className="c-filters__popup-bottom">
            <button type="button" className="c-filters__popup-submit c-btn u-tap-size" onClick={handleFilterClick}>
              Filtrar
              <span className="c-btn__icon icon-carousel-arrow"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default BranchesHeaderTypeFilters;
