import { useState } from "react";
import { API_DEFAULT_IMAGES_URL, API_IMAGES_URL } from "../configs/api";
import { BranchesListPlaceholder } from "./BranchesListPlaceholder";

const BranchesList = ({ stores, handleHideList, handleBranchOpen }) => {
  const [hoverItem, setHoverItem] = useState(null);

  const handleItemHoverIn = (storeId) => {
    setHoverItem(storeId);
  };

  const handleItemHoverOut = () => {
    setHoverItem(null);
  };

  const handleBranchClick = (store) => {
    handleBranchOpen && handleBranchOpen(store);
  };

  return (
    <div className="c-branches__holder js-branchesList">
      <div className="c-branches__list-top">
        <p className="c-branches__holder-title">{stores?.length ?? 0} locales encontrados</p>
        <button className="c-branches__close u-tap-size" onClick={() => handleHideList && handleHideList()}>
          <span className="c-branches__list-close-icon icon-close"></span>
        </button>
      </div>

      {stores?.length === 0 ? (
        <BranchesListPlaceholder />
      ) : (
        <ul className="c-branches__list">
          {stores?.map((store) => {
            const imageURL = store.picture?.filename
              ? `${API_IMAGES_URL}${store.picture?.id}/124/${store.picture?.filename}`
              : API_DEFAULT_IMAGES_URL;
            return (
              <li
                className={`c-branches__item ${
                  hoverItem !== null ? (hoverItem === store.id ? "is-hover" : "is-not-hover") : ""
                } ${store.isClosed || !store.isOpen ? "has-closed" : ""}`}
                key={store.id}
                onMouseEnter={() => handleItemHoverIn(store.id)}
                onMouseLeave={() => handleItemHoverOut()}
                onClick={() => handleBranchClick(store)}
              >
                <div className="c-branches__item-image">
                  <picture className="c-pic c-pic--abs">
                    <img className="c-pic__img" src={imageURL} alt={store.name} />
                  </picture>
                </div>
                <div className="c-branches__item-resume">
                  {store.isCoffee && <span className="c-branches__type type-coffee">Caffè</span>}
                  {store.isExpress && <span className="c-branches__type type-express">Express</span>}
                  <p className="c-branches__name">
                    <strong>{store.name}</strong>
                  </p>
                  <p className="c-branches__location">{store.town}</p>
                  <span className="c-branches__schedule">
                    <span
                      className={`c-branches__schedule-icon ${store.isClosed ? "icon-sold-out" : "icon-schedule"}`}
                    ></span>
                    <span className="c-branches__schedule-text">
                      {store.isClosed
                        ? "Cerrado temporalmente"
                        : `${store.isOpen ? "Cierra" : "Abre"} a las ${store.nextHour} hs.`}
                    </span>
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default BranchesList;
