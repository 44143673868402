import { useEffect, useState } from "react";
import BranchesHeaderFilters from "../components/BranchesHeaderFilters";
import BranchesHeaderTop from "../components/BranchesHeaderTop";
import BranchesList from "../components/BranchesList";
import BranchesMap from "../components/BranchesMap";
import BranchesSheet from "../components/BranchesSheet";
import { BranchPopupOpener } from "../components/BranchPopupOpener";
import storesService from "../services/stores";

const Search = () => {
  //UI
  const startLocationVisible = window?.location?.href.indexOf("showlocalsearch=1") > -1;
  const [listPopupState, setListPopupState] = useState(false);
  const [popupState, setPopupState] = useState(startLocationVisible);

  //Stores
  const [allStores, setAllStores] = useState([]);
  const [stores, setStores] = useState([]);
  const [states, setStates] = useState([]);
  const [towns, setTowns] = useState([]);
  const [activeStore, setActiveStore] = useState(null);

  //Filters
  const [activeState, setActiveState] = useState("");
  const [activeTown, setActiveTown] = useState("");
  const [activeIsOpen, setActiveIsOpen] = useState(false);
  const [activeIsExpress, setActiveIsExpress] = useState(true);
  const [activeIsCoffee, setActiveIsCoffee] = useState(true);

  //Helper function
  const handleFilter = (filters) => {
    let town = "town" in filters ? filters.town : activeTown;
    const state = "state" in filters ? filters.state : activeState;
    const isOpen = "isOpen" in filters ? filters.isOpen : activeIsOpen;
    const isExpress = "isExpress" in filters ? filters.isExpress : activeIsExpress;
    const isCoffee = "isCoffee" in filters ? filters.isCoffee : activeIsCoffee;
    const stateobj = storesService.getStateObj(states, state);
    const towns = stateobj?.towns ?? [];

    //Check town
    if (!town) {
      town = storesService.getStartTown(stateobj);
    }

    //Filter stores
    const storesFiltered = storesService.filterStores(allStores, { state, town, isOpen, isExpress, isCoffee });

    //Update states
    setActiveState(state);
    setActiveTown(town);
    setActiveIsOpen(isOpen);
    setActiveIsExpress(isExpress);
    setActiveIsCoffee(isCoffee);
    setStores(storesFiltered);
    setTowns(towns);
  };

  const handleBranchOpen = (store) => {
    setActiveStore(store);
  };

  //Handle filter at start
  useEffect(() => {
    const effect = async () => {
      const storesExtraData = await storesService.fetchStoresExtraData();
      const storesJson = storesService.getAllStores(storesExtraData);
      const statesJson = storesService.getAllStatesFromStores(storesJson);
      const startStateJson = storesService.getStartState(statesJson);
      const startTownJson = storesService.getStartTown(startStateJson);

      //Update states
      setAllStores(storesJson);
      setStates(statesJson);
      setTowns(startStateJson.towns);
      setActiveState(startStateJson.state);
      setActiveTown(startTownJson);

      //Initial state
      setStores(
        storesService.filterStores(storesJson, {
          state: startStateJson.state,
          town: startTownJson,
          isOpen: false,
          isExpress: true,
          isCoffee: true,
        })
      );
    };
    effect();
  }, []);

  return (
    <div id="branches-popup" className={`c-branches c-popup c-popup-simple ${popupState && "is-visible"}`}>
      <div className="c-branches__header">
        <BranchPopupOpener handlePopupOpen={() => setPopupState(true)} />
        <BranchesHeaderTop storesCount={stores.length} handlePopupClose={() => setPopupState(false)} />
        <BranchesHeaderFilters
          states={states}
          towns={towns}
          activeState={activeState}
          activeTown={activeTown}
          activeIsOpen={activeIsOpen}
          activeIsExpress={activeIsExpress}
          activeIsCoffee={activeIsCoffee}
          handleFilter={handleFilter}
          handleShowList={() => setListPopupState(true)}
        />
      </div>

      <div className="c-branches__body">
        <div className={`c-branches__overlay ${(listPopupState || activeStore) && "is-visible"}`}>
          <BranchesList
            stores={stores}
            handleHideList={() => setListPopupState(false)}
            handleBranchOpen={handleBranchOpen}
          />
          <BranchesSheet store={activeStore} handleClose={(e) => setActiveStore(null)} />
        </div>

        <div className="c-branches__map">
          {popupState && <BranchesMap stores={stores} storeCenter={activeStore} handleBranchOpen={handleBranchOpen} />}
        </div>
      </div>
    </div>
  );
};
export default Search;
