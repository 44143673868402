const BranchesHeaderDesktopFilters = ({ states, activeState, towns, activeTown, handleFilter }) => {
  const handleStateChange = (event) => {
    handleFilter &&
      handleFilter({
        state: event.target.value,
        town: "",
      });
  };
  const handleTownChange = (event) => {
    handleFilter &&
      handleFilter({
        town: event.target.value,
      });
  };

  return (
    <div className="c-filters__group c-filters__group--location u-hidden-xs">
      <span className="c-filters__text">Ubicación</span>
      <form>
        <label className="c-filters__select" style={{ display: "inline-block" }}>
          <span className="c-filters__label c-filters__label--subtitle">Departamento:</span>
          <span className="c-filters__select-holder">
            <select
              value={activeState}
              onChange={handleStateChange}
              className="c-filters__input c-filters__input--select"
              name="city"
            >
              <option value="" disabled>
                Seleccione un departamento
              </option>
              {states?.map((state, index) => {
                return (
                  <option value={state.state} key={index}>
                    {state.state}
                  </option>
                );
              })}
            </select>
            <span className="c-filters__select-text">
              <span className="c-filters__select-span">
                {!!activeState ? activeState : "Seleccione un departamento"}
              </span>
              <span className="c-filters__select-arrow icon-select-arrow"></span>
            </span>
          </span>
        </label>
        <label className="c-filters__select" style={{ display: "inline-block" }}>
          <span className="c-filters__label c-filters__label--subtitle">Barrio:</span>
          <span className="c-filters__select-holder">
            <select
              value={activeTown}
              onChange={handleTownChange}
              className="c-filters__input c-filters__input--select"
              name="town"
            >
              <option value="">Seleccione un barrio</option>
              {towns?.map((town, index) => {
                return (
                  <option value={town} key={index}>
                    {town}
                  </option>
                );
              })}
            </select>
            <span className={`c-filters__select-text ${activeTown ? "" : "c-filters__select-text--placeholder"}`}>
              <span className="c-filters__select-span">{!!activeTown ? activeTown : "Seleccione un barrio"}</span>
              <span className="c-filters__select-arrow icon-select-arrow"></span>
            </span>
          </span>
        </label>
      </form>
    </div>
  );
};
export default BranchesHeaderDesktopFilters;
