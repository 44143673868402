export const BranchesListPlaceholder = () => {
  return (
    <div
      style={{
        margin: "30% 20%",
        fontSize: "2em",
        opacity: ".7",
        textAlign: "center",
      }}
    >
      <img src="assets/images/not-found.png" style={{ width: "100px", marginBottom: "20px" }} />
      <p>Ningún local coincide con los criterios seleccionados, intentá ampliar tu búsqueda.</p>
    </div>
  );
};
