import { useState } from "react";
import BranchesHeaderDesktopFilters from "./BranchesHeaderDesktopFilters";
import BranchesHeaderTypeFilters from "./BranchesHeaderTypeFilters";
import BranchesHeaderMobileFilters from "./BranchesHeaderMobileFilters";
import BranchesHeaderResumeFilters from "./BranchesHeaderResumeFilters";

const BranchesHeaderFilters = ({
  states,
  activeState,
  towns,
  activeTown,
  activeIsOpen,
  activeIsExpress,
  activeIsCoffee,
  handleFilter,
  handleShowList,
}) => {
  //UI
  const [popupTypesState, setPopupTypesState] = useState(false);
  const [popupMobileFiltersState, setPopupMobileFiltersState] = useState(false);

  return (
    <div className="c-branches__header-filters c-filters">
      <BranchesHeaderResumeFilters
        activeState={activeState}
        activeTown={activeTown}
        handleTypePopupState={setPopupTypesState}
        handleStatePopupState={setPopupMobileFiltersState}
        handleShowList={handleShowList}
      />
      <BranchesHeaderDesktopFilters
        states={states}
        towns={towns}
        activeState={activeState}
        activeTown={activeTown}
        handleFilter={handleFilter}
      />
      <BranchesHeaderTypeFilters
        popupState={popupTypesState}
        handlePopupState={setPopupTypesState}
        activeIsOpen={activeIsOpen}
        activeIsExpress={activeIsExpress}
        activeIsCoffee={activeIsCoffee}
        handleFilter={handleFilter}
      />
      <BranchesHeaderMobileFilters
        popupState={popupMobileFiltersState}
        handlePopupState={setPopupMobileFiltersState}
        states={states}
        towns={towns}
        activeState={activeState}
        activeTown={activeTown}
        handleFilter={handleFilter}
      />
    </div>
  );
};
export default BranchesHeaderFilters;
